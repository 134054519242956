import '../styles.css';
import './noise.css';

// Animation settings for the noise layer and color overlay
const animateNoise = () => {
    const noiseLayer = document.getElementById('noise-layer');
    const colorOverlay = document.getElementById('color-overlay');
    if (!noiseLayer || !colorOverlay) return;

    // Color palette for the overlay
    const colors = [
        'rgb(255, 0, 0)',     // Red
        'rgb(0, 0, 255)',     // Blue
        'rgb(128, 0, 128)',   // Purple
        'rgb(0, 128, 0)',     // Green
        'rgb(255, 165, 0)',   // Orange
        'rgb(255, 0, 255)',   // Magenta
        'rgb(0, 128, 128)'    // Teal
    ];

    let currentColorIndex = 0;

    // Function to cycle colors
    const cycleColors = () => {
        currentColorIndex = (currentColorIndex + 1) % colors.length;
        colorOverlay.style.backgroundColor = colors[currentColorIndex];

        // Schedule next color change
        setTimeout(cycleColors, 5000 + Math.random() * 3000); // Change color every 5-8 seconds
    };

    // Start color cycling
    colorOverlay.style.backgroundColor = colors[currentColorIndex];
    cycleColors();

    // Animation function
    const animate = () => {
        // Generate random transforms
        const translateX = Math.random() * 10 - 5; // -5px to 5px
        const translateY = Math.random() * 10 - 5; // -5px to 5px
        const rotate = Math.random() * 4 - 2; // -1deg to 1deg
        const scale = 1 + (Math.random() * 0.04 - 0.02); // 0.98 to 1.02

        // Apply transforms with smooth transition
        noiseLayer.style.transform = `translateX(${translateX}px) translateY(${translateY}px) rotate(${rotate}deg) scale(${scale})`;

        // Schedule next animation frame
        setTimeout(animate, 0 + Math.random() * 2000); // Random timing between 2-3 seconds
    };

    // Start animation
    animate();
};

// Initialize noise animation when page loads
window.addEventListener('load', animateNoise);

interface Link {
    name: string;
    url: string;
}

const links: Link[] = [
    { name: "🎥 Videos", url: "https://www.youtube.com/channel/UCfXiXFua6E3Kv4aaeR6Wntw"},
    { name: "🎵 Music", url: "https://open.spotify.com/artist/3IfEuiZUXZOkxwYdBrsKfa"},
    { name: "📷 Insta", url: "https://www.instagram.com/prole.rocks/"},
    { name: "🤘 Social", url: "https://www.facebook.com/alanhughesmusic/"}
    // Add more links as needed
];

// With Vite, we don't need to wait for DOMContentLoaded
const main = document.querySelector("main");
if (main) {
    links.forEach(link => {
        const a = document.createElement("a");
        a.href = link.url;
        a.className = "flex items-center px-2 py-1 space-x-2 bg-gradient-to-r from-red-700 to-red-500 rounded border-2 shadow-md transition-transform border-white/40 hover:shadow-lg hover:shadow-red-600/50 hover:scale-105";
        a.innerHTML = `<span>${link.name}</span>`;
        a.target = '_blank';
        main.appendChild(a);
    });
}
